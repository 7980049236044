import moment from 'moment-timezone'


export const DateUtil = {

    getFormattedDate(dateStr) {
        if (dateStr) {
            let format = 'MMMM DD, YYYY'
            return moment(dateStr).tz('Asia/Manila').format(format)
        }
        return ''
    },

    getFormattedDateWithTime(dateStr) {
        if (dateStr) {
            let format = 'MMMM DD, YYYY, hh:mm A'
            return moment(dateStr).tz('Asia/Manila').format(format);
        }
        return '';
    },

    getFormattedDateWithTime2(dateStr) {
        if (dateStr) {
            let format = 'MMMM DD, YYYY, [at] hh:mm A'
            return moment(dateStr).tz('Asia/Manila').format(format);
        }
        return '';
    },

    getFormattedShortDateWithTime(timestamp) {
        if (timestamp) {
            let format = 'MM/DD/yyyy HH:mm:ss'
            return moment(timestamp).tz('Asia/Manila').format(format);
        }
        return '-';
    },

    getDateInDDMMYYYYHHSSFormat(timeStamp) {
        let dateTime = new Date(timeStamp);
        return moment(dateTime).format('DDMMYYYYHHmmss');
    },

    getDateInMMDDYYYYFormat(timeStamp) {
        let dateTime = new Date(timeStamp);
        return moment(dateTime).format('MMDDYYYY');
    },

    roundDate(timeStamp) {
        let d = new Date(timeStamp)
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(0)
        d.setMilliseconds(0)
        return d.getTime()
    },

    getNoOfDays(dateFrom, dateTo) {
        // make sure that the inputs are in date format
        dateFrom = new Date(dateFrom);
        dateTo = new Date(dateTo);
        // get the difference in days
        let diff = Math.abs(dateFrom.getTime() - dateTo.getTime());
        let day = Math.ceil(diff / (1000 * 3600 * 24));
        return parseInt(day)
    },

    displayTimeDifference(dateFrom, dateTo) {
        // make sure that the inputs are in date format
        dateFrom = new Date(dateFrom);
        dateTo = new Date(dateTo);

        // get the difference in minutes
        let diff = Math.abs(dateFrom.getTime() - dateTo.getTime());
        let minutes = Math.ceil(diff / (1000 * 60)); // Calculate difference in minutes

        // Calculate hours and minutes
        let hours = Math.floor(minutes / 60);
        let remainingMinutes = minutes % 60;

        // Calculate days and remaining hours
        let days = Math.floor(hours / 24);
        let remainingHours = hours % 24;

        // Construct display string
        let displayString = '';
        if (days > 0) {
            displayString += days + ' day(s), ';
        }
        if (remainingHours > 0) {
            displayString += remainingHours + ' hour(s), ';
        }
        displayString += remainingMinutes + ' minute(s)';

        return displayString;
    },

    getCurrentMonth() {
        return parseInt(moment().tz('Asia/Manila').format('M'));
    },

    getCurrentYear() {
        return parseInt(moment().tz('Asia/Manila').format('YYYY'));
    },

    startDateTimeStamp(date) {
        if (Object.prototype.toString.call(date) === '[object Date]') {
            return this.roundDate(date.getTime())
        } else {
            return this.roundDate(date);
        }
    },

    endDateTimeStamp(date) {
        let addOneDay = 60 * 60 * 24 * 1000
        if (Object.prototype.toString.call(date) === '[object Date]') {
            return this.roundDate(date.getTime()) + addOneDay - 1
        } else {
            return this.roundDate(date) + addOneDay - 1
        }
    },

    // Param: dateStrng with "mm-dd-yyyy" format
    getTimestamp(dateString) {
        let strArr = dateString.split('-');
        let month = parseInt(strArr[0]) - 1;
        let day = parseInt(strArr[1]);
        let year = parseInt(strArr[2]);
        let date = new Date();
        date.setFullYear(year);
        date.setMonth(month);
        date.setDate(day);
        return date.getTime();
    },

    getCurrentTimestamp() {
        return new Date().getTime();
    },

    getMonthName(month) {
        return moment().month(month - 1).format('MMMM');
    },

    getTimestampRangeForMonth: (year, month) => {
        // Ensure month is in the range 1-12
        month = Math.max(1, Math.min(12, month));

        // Create Date objects for the first and last day of the month
        const firstDay = new Date(year, month - 1, 1); // month is 0-indexed
        const lastDay = new Date(year, month, 0); // day 0 is the last day of the previous month

        // Get timestamps for the first and last day of the month
        const firstDayTimestamp = firstDay.getTime();
        const lastDayTimestamp = lastDay.getTime() + 86400000 - 1; // Add 1 day and subtract 1 millisecond to get the end of the last day

        return { start: firstDayTimestamp, end: lastDayTimestamp };
    }
}
