import Vue from 'vue';
import Router from 'vue-router';

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');
const AdminContainer = () => import('@/containers/AdminContainer');

// Common Views
const Page404 = () => import('@/views/commons/Page404');
const Page500 = () => import('@/views/commons/Page500');
const PrivacyPolicy = () => import('@/views/commons/PrivacyPolicy');
const Dashboard = () => import('@/views/commons/Dashboard');

// Account
const ResetPassword = () => import('@/views/account/ResetPassword');
const Login = () => import('@/views/account/Login');

// Account Setup
const Company = () => import('@/views/setup/Company');
const StorageLocation = () => import('@/views/setup/StorageLocation');
const Connection = () => import('@/views/setup/Connection');
const User = () => import('@/views/setup/User');
const Transportation = () => import('@/views/setup/Transportation');
const AssetType = () => import('@/views/setup/AssetType');

// Asset-Based
const Asset = () => import('@/views/asset/Asset');
const StickerGenerator = () => import('@/views/asset/StickerGenerator');
const AssetIssuance = () => import('@/views/asset/AssetIssuance');
const AssetHistoryLog = () => import('@/views/asset/AssetHistoryLog');

// Transactions
const InventorySession = () => import('@/views/transactions/InventorySession');
const Dispatch = () => import('@/views/transactions/Dispatch');
const AddDispatch = () => import('@/views/transactions/dispatch/AddDispatch');
const EditDispatch = () => import('@/views/transactions/dispatch/EditDispatch');
const Receipt = () => import('@/views/transactions/Receipt');

// Maintenance
const Disposal = () => import('@/views/maintenance/Disposal')
const Repair = () => import('@/views/maintenance/Repair')

// Reports
const AssetAccountability = () => import('@/views/reports/AssetAccountability');
const AssetPoolDistribution = () => import('@/views/reports/AssetPoolDistribution');
const InventorySummary = () => import('@/views/reports/InventorySummaryReport');
const InventoryCompliance = () => import('@/views/reports/InventoryComplianceReport');
const InventoryScanProgress = () => import('@/views/reports/InventoryScanProgressReport');
const UserInventoryScans = () => import('@/views/reports/UserInventoryScansReport');
const AssetsWithTruckers = () => import('@/views/reports/AssetsWithTruckersReport');
const AssetReconReport = () => import('@/views/reports/AssetReconReport');

// Support
const StencilGenerator = () => import('@/views/support/StencilGenerator');
const TransferCompanyRequests = () => import('@/views/support/TransferCompanyRequests');
const UserSessionLogs = () => import('@/views/support/UserSessionLogs');
const UserTickets = () => import('@/views/support/UserTickets');

// Data Importer
const DispatchImporter = () => import('@/views/support/DispatchImporter');
const AssetImporter = () => import('@/views/support/AssetImporter');
const CompanyImporter = () => import('@/views/support/CompanyImporter');
const StorageLocationImporter = () => import('@/views/support/StorageLocationImporter');
const UserImporter = () => import('@/views/support/UserImporter');
const TransportationImporter = () => import('@/views/support/TransportationImporter');

// Website Content
const Faq = () => import('@/views/website/Faq');
const Blog = () => import('@/views/website/Blog');
const Inquiries = () => import('@/views/website/Inquiries');

Vue.use(Router);

export default new Router({
	mode: 'hash', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'open active',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: [
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: PrivacyPolicy
		},
		{
			path: '/404',
			name: 'Page 404',
			component: Page404,
			alias: '*'
		},
		{
			path: '/500',
			name: 'Page 500',
			component: Page500
		},
		{
			path: '/reset-password',
			name: 'Reset Password',
			component: ResetPassword
		},
		{
			path: '/admin',
			redirect: '/admin/dashboard',
			name: 'Home',
			component: AdminContainer,
			children: [
				{
					path: 'dashboard',
					name: 'Dashboard',
					component: Dashboard
				},

				// Setup
				{
					path: 'company',
					name: 'Company',
					component: Company
				},
				{
					path: 'admin-location',
					name: 'Storage Location',
					component: StorageLocation
				},
				{
					path: 'admin-user',
					name: 'User',
					component: User
				},
				{
					path: 'admin-connection',
					name: 'Connection',
					component: Connection
				},
				{
					path: 'admin-transportation',
					name: 'Transportation',
					component: Transportation
				},
				{
					path: 'admin-asset-type',
					name: 'Asset Type',
					component: AssetType
				},

				// Asset Management
				{
					path: 'admin-asset',
					name: 'Asset',
					component: Asset
				},
				{
					path: 'admin-asset-pool-distribution',
					name: 'Asset Pool Distribution',
					component: AssetPoolDistribution
				},
				{
					path: 'admin-asset-accountability',
					name: 'Asset Accountability',
					component: AssetAccountability
				},
				{
					path: 'sticker-generator',
					name: 'Sticker Generator',
					component: StickerGenerator
				},
				{
					path: 'admin-asset-issuance',
					name: 'Asset Issuance',
					component: AssetIssuance
				},
				{
					path: 'admin-asset-history-log',
					name: 'Asset History Log',
					component: AssetHistoryLog
				},

				// Transactions
				{
					path: 'admin-dispatch',
					name: 'Dispatch',
					component: Dispatch
				},
				{
					path: 'admin-add-dispatch',
					name: 'Add Dispatch',
					component: AddDispatch,
					props: true
				},
				{
					path: 'admin-edit-dispatch',
					name: 'Edit Dispatch',
					component: EditDispatch,
					props: true
				},
				{
					path: 'admin-receipt',
					name: 'Receipt',
					component: Receipt
				},
				{
					path: 'admin-inventory-session',
					name: 'Inventory Session',
					component: InventorySession
				},

				// Maintenance
				{
					path: 'admin-repair',
					name: 'Repair Management',
					component: Repair
				},
				{
					path: 'admin-repair/:id',
					name: 'Repair Management',
					component: Repair
				},
				{
					path: 'admin-disposal',
					name: 'Disposal Management',
					component: Disposal
				},
				{
					path: 'admin-disposal/:id',
					name: 'Disposal Management',
					component: Disposal
				},

				// Reports
				{
					path: 'admin-inventory-summary',
					name: 'Inventory Summary',
					component: InventorySummary
				},
				{
					path: 'admin-inventory-compliance',
					name: 'Inventory Compliance',
					component: InventoryCompliance
				},
				{
					path: 'admin-inventory-scan-progress',
					name: 'Inventory Scan Progress',
					component: InventoryScanProgress
				},
				{
					path: 'admin-user-inventory-scans',
					name: 'User Inventory Scans',
					component: UserInventoryScans
				},
				{
					path: 'admin-assets-with-truckers',
					name: 'Assets With Truckers',
					component: AssetsWithTruckers
				},
				{
					path: 'admin-asset-recon',
					name: 'Asset Recon',
					component: AssetReconReport
				},

				// Support
				{
					path: 'admin-stencil-generator',
					name: 'Stencil Generator',
					component: StencilGenerator
				},
				{
					path: 'admin-transfer-company-requests',
					name: 'Transfer Company Requests',
					component: TransferCompanyRequests
				},
				{
					path: 'admin-user-session-logs',
					name: 'User Session Logs',
					component: UserSessionLogs
				},
				{
					path: 'admin-user-tickets',
					name: 'User Tickets',
					component: UserTickets
				},


				// Data Importer
				{
					path: 'company-importer',
					name: 'Company Importer',
					component: CompanyImporter
				},
				{
					path: 'storage-location-importer',
					name: 'Storage Location Importer',
					component: StorageLocationImporter
				},
				{
					path: 'user-importer',
					name: 'User Importer',
					component: UserImporter
				},
				{
					path: 'asset-importer',
					name: 'Asset Importer',
					component: AssetImporter
				},
				{
					path: 'dispatch-importer',
					name: 'Dispatch Importer',
					component: DispatchImporter
				},
				{
					path: 'transportation-importer',
					name: 'Transportation Importer',
					component: TransportationImporter
				},

				// Website
				{
					path: 'faqs',
					name: 'FAQs',
					component: Faq
				},
				{
					path: 'blogs',
					name: 'Blogs',
					component: Blog
				},
				{
					path: 'inquiries',
					name: 'Inquiries',
					component: Inquiries
				}
			]
		},
		{
			path: '/',
			redirect: '/dashboard',
			name: 'Home',
			component: DefaultContainer,
			children: [
				{
					path: 'dashboard',
					name: 'Dashboard',
					component: Dashboard
				},

				// Setup
				{
					path: 'company',
					name: 'Company',
					component: Company
				},
				{
					path: 'location',
					name: 'Storage Location',
					component: StorageLocation
				},
				{
					path: 'user',
					name: 'User',
					component: User
				},
				{
					path: 'connection',
					name: 'Connection',
					component: Connection
				},
				{
					path: 'transportation',
					name: 'Transportation',
					component: Transportation
				},
				{
					path: 'asset-type',
					name: 'Asset Type',
					component: AssetType
				},

				// Asset Management
				{
					path: 'asset',
					name: 'Asset',
					component: Asset
				},
				{
					path: 'asset-pool-distribution',
					name: 'Asset Pool Distribution',
					component: AssetPoolDistribution
				},
				{
					path: 'asset-accountability',
					name: 'Asset Accountability',
					component: AssetAccountability
				},
				{
					path: 'sticker-generator',
					name: 'Sticker Generator',
					component: StickerGenerator
				},
				{
					path: 'asset-issuance',
					name: 'Asset Issuance',
					component: AssetIssuance
				},
				{
					path: 'asset-history-log',
					name: 'Asset History Log',
					component: AssetHistoryLog
				},

				// Transactions
				{
					path: 'dispatch',
					name: 'Dispatch',
					component: Dispatch
				},
				{
					path: 'add-dispatch',
					name: 'Add Dispatch',
					component: AddDispatch,
					props: true
				},
				{
					path: 'edit-dispatch',
					name: 'Edit Dispatch',
					component: EditDispatch,
					props: true
				},
				{
					path: 'receipt',
					name: 'Receipt',
					component: Receipt
				},
				{
					path: 'inventory-session',
					name: 'Inventory Session',
					component: InventorySession
				},

				// Maintenance
				{
					path: 'repair',
					name: 'Repair Management',
					component: Repair
				},
				{
					path: 'repair/:id',
					name: 'Repair Management',
					component: Repair
				},
				{
					path: 'disposal',
					name: 'Disposal Management',
					component: Disposal
				},
				{
					path: 'disposal/:id',
					name: 'Disposal Management',
					component: Disposal
				},

				// Reports
				{
					path: 'inventory-summary',
					name: 'Inventory Summary',
					component: InventorySummary
				},
				{
					path: 'inventory-compliance',
					name: 'Inventory Compliance',
					component: InventoryCompliance
				},
				{
					path: 'inventory-scan-progress',
					name: 'Inventory Scan Progress',
					component: InventoryScanProgress
				},
				{
					path: 'user-inventory-scans',
					name: 'User Inventory Scans',
					component: UserInventoryScans
				},
				{
					path: 'assets-with-truckers',
					name: 'Assets With Truckers',
					component: AssetsWithTruckers
				},
				{
					path: 'asset-recon',
					name: 'Asset Recon',
					component: AssetReconReport
				},

				// Support
				{
					path: 'stencil-generator',
					name: 'Stencil Generator',
					component: StencilGenerator
				},
				{
					path: 'transfer-company-requests',
					name: 'Transfer Company Requests',
					component: TransferCompanyRequests
				},
				{
					path: 'user-session-logs',
					name: 'User Session Logs',
					component: UserSessionLogs
				},
				{
					path: 'user-tickets',
					name: 'User Tickets',
					component: UserTickets
				},
			]
		}
	]
});
