import _ from 'lodash';

export default {
	namespace: true,

	state: {
		repairs: {},
		currRepair: {},
	},

	mutations: {
		SET_REPAIRS(state, repairs) {
			state.repairs = repairs;
		},
		SET_CURR_SET_REPAIRS(state, currRepair) {
			state.currRepair = currRepair;
		},
	},

	actions: {
		setAllrepairs(vuexContext, repairs) {
			vuexContext.commit('SET_REPAIRS', repairs);
		},
		updateAllrepairs(vuexContext, newRepairs) {
			let repairs = vuexContext.getters.repairs;

			_.forEach(newRepairs, (value, key) => {
				repairs[key] = value;
			});

			vuexContext.commit('SET_REPAIRS', repairs);
		},
	},

	getters: {
		repairs(state) {
			if (!_.isEmpty(state.repairs)) {
				return state.repairs;
			}
			return {};
		},
		currRepair(state) {
			if (!_.isEmpty(state.currRepair)) {
				return state.currRepair;
			}
			return {};
		},
	},
};
