<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

export default {
  name: "app",
  created() {
    Validator.extend("selectRequired", {
      getMessage: (field) => "The " + field + " field is required",
      validate: (value) => value.name !== " - Please select - ",
    });
    Validator.extend("positive_decimal", {
      validate(value) {
        // Regular expression to match positive decimal numbers
        return /^[+]?\d*\.?\d+$/.test(value) && parseFloat(value) >= 0;
      },
      message: "Please enter a valid positive decimal number.",
    });
  },
  mounted() {
    // Prevents history traversal from Login page to other pages
    window.onpopstate = () => {
      if (this.$route.path == "/login") {
        this.$router.push("/login");
      }
    };
  },
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";

@import "./App.scss";

.help-block {
  color: #f44335;
  font-size: 12px;
  margin-bottom: 20px !important;
}

.input-with-validation {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
}

p {
  font-size: medium !important;
}

b {
  font-size: medium;
}

.dropdown-menu {
  font-size: medium;
}

.list {
  font-size: large;
}

label {
  font-size: medium;
}

.filter {
  font-size: medium;
}

.btn-dark {
  font-size: medium;
}

.btn-success {
  font-size: medium;
}

.btn-warning {
  font-size: medium;
}

.btn-primary {
  font-size: medium;
}

.btn-danger {
  font-size: medium;
}

.btn-secondary {
  font-size: medium;
}

.col-form-label {
  font-size: medium;
}

.form-control {
  font-size: medium;
}

.text-muted {
  font-size: small;
}

.vs__selected {
  font-size: medium;
}

.card-subtitle {
  font-size: medium !important;
}

.numFont {
  font-family: monospace;
  font-size: large;
}

// ------ Filter Option and Table Styles ------
.total-display {
  line-height: 35px;
  font-weight: bold;
  font-size: 14px;
}

.btn-table-options {
  margin-top: 30px;
  margin-bottom: 10px;
}

.reset-button {
  margin-top: 28px;
}

.add-button {
  margin-top: 30px;
}

.header {
  font-weight: bold;
  font-size: 14px;
}

.confirm-message {
  margin: 10px;
}

.sidebar .nav {
  width: 215px;
  min-height: 100%;
}

table {
  font-size: medium;
}

a {
  color: #f18f01 !important;
}

a:active {
  color: #122c91 !important;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 20px;
  padding-left: 5px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  width: 100%;
  padding-top: 0.1rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

div.v-toaster > span > div {
  word-wrap: break-word;
}

.toggle-btn {
  border: 0 !important;
  background: white !important;
  color: #20a8d8 !important;
}

.details-view-title {
  font-weight: bold;
  font-size: medium;
}

.details-view-subtitle {
  font-size: small;
  font-style: italic;
  color: #73818f !important;
  margin-bottom: 10px;
}

select {
  font-size: medium !important;
}

fieldset div ul {
  font-size: medium !important;
}

.truncate-text {
  font-style: italic;
}

.company-display {
  font-size: medium !important;
  font-weight: bold;
}

.location-display {
  font-size: small !important;
  font-style: italic;
}
.app {
  background-color: #dbdfef;
}
.card-border {
  box-shadow: 2px 2px 10px #cfcfcf;
  border-radius: 10px;
}
//Sidenav
.sidebar {
  overflow: hidden;
  background-clip: padding-box;
}

.sidebar .nav-link:hover {
  background-color: #122c91 !important;
  color: white !important;
}

.sidebar .nav-link:hover i {
  color: #f18f01 !important;
}

.sidebar .nav-link.active {
  background-color: #122c91 !important;
  color: white !important;
}

.sidebar .nav-link.active i {
  color: #f18f01 !important;
}

.sidebar .nav-item.nav-dropdown.open > .nav-link {
  color: #f18f01 !important;
}

.sidebar .nav-item.nav-dropdown.open > .nav-dropdown-items .nav-link {
  color: white !important;
}
</style>
